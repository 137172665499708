@import url(https://fonts.googleapis.com/css?family=Poppins:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@400;600;700&display=swap);
@font-face {
	font-family: 'proximanova-light';
	src: url(/static/media/proximanova-light.d28eded7.eot);
	src: local(proximanova-light),
	url(/static/media/proximanova-light.fe70f484.woff2) format('woff2'),
	url(/static/media/proximanova-light.fe70f484.woff) format('woff'),
	url(/static/media/proximanova-light.80704f49.ttf) format('truetype'),
	url(/static/media/proximanova-light.ac51ad8a.svg) format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'proximanova-regular';
	src: url(/static/media/proximanova-regular.0f42bf88.eot);
	src: local(proximanova-regular),
	url(/static/media/proximanova-regular.8e0d45b1.woff2) format('woff2'),
	url(/static/media/proximanova-regular.8e0d45b1.woff) format('woff'), 
	url(/static/media/proximanova-regular.59f5b8c5.ttf) format('truetype'), 
	url(/static/media/proximanova-regular.bf9f5d50.svg) format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'proximanova-semibold';
	src: url(/static/media/proximanova-semibold.42c649a0.eot);
	src: local(proximanova-semibold),
	url(/static/media/proximanova-semibold.0560fccd.woff2) format('woff2'),
	url(/static/media/proximanova-semibold.0560fccd.woff) format('woff'),
	url(/static/media/proximanova-semibold.44d192c1.ttf) format('truetype'),
	url(/static/media/proximanova-semibold.f14eee64.svg) format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FontAwesome';
	src: url(/static/media/fontawesome-webfont.674f50d2.eot);
	src: local(FontAwesome),
	url(/static/media/fontawesome-webfont.674f50d2.eot) format('embedded-opentype'),
	url(/static/media/fontawesome-webfont.af7ae505.woff2) format('woff2'),
	url(/static/media/fontawesome-webfont.fee66e71.woff) format('woff'),
	url(/static/media/fontawesome-webfont.b06871f2.ttf) format('truetype'),
	url(/static/media/fontawesome-webfont.912ec66d.svg) format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
  }

html {
	position: relative;
	min-height: 100%;
}
.btn, .btn-primary, .btn:focus, .btn:active, input, textarea {
	outline: none !important;
	box-shadow: none!important;
}
ul, ol {
	padding: 0;
	margin: 0;
}
body {
	height: 100%;
	font-family: 'proximanova-light', sans-serif;
	background-color: #fff;
	color: #323332;
	font-size: 18px;
	overflow-x: hidden;
	letter-spacing: 1px
}
.no-scroll {
	overflow: hidden
}
h1, h2, h3, h4, h5, h6, h7, h8, .button, ._button-arrow p, .footer ._ph, .es_menu ul li a, .SN{
	font-family: 'Poppins', sans-serif;
	letter-spacing: 1px;
	font-weight: 700
}
.topheader {
	width: 100%;
	position: fixed;
	z-index: 100;
	padding: 30px 20px 0px
}
.EmberlineStudios {
	float: left;
	width: 80px;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.EmberlineStudios-m {
	padding-top: 30px;
	padding-left: 20px;
	float: left;
	width: 100px;
}
.ESlogo .st0 {
	stroke-width: 1;
	stroke-miterlimit: 10;
	stroke: rgb(238,57,57);
	fill: rgb(238,57,57);
	stroke-dasharray: 1400;
	opacity: 10;
	-webkit-animation: ESlogo 1.5s cubic-bezier(0, .23, 1, .1);
	        animation: ESlogo 1.5s cubic-bezier(0, .23, 1, .1);
}
.EmberlineStudios-m .ESlogo .st0 {
	stroke-width: 1;
	stroke-miterlimit: 10;
	stroke: rgb(255,255,255);
	fill: rgb(255,255,255);
	stroke-dasharray: 1400;
	opacity: 10;
	-webkit-animation: ESlogo 1.5s cubic-bezier(0, .23, 1, .1);
	        animation: ESlogo 1.5s cubic-bezier(0, .23, 1, .1);
}
 @-webkit-keyframes ESlogo {
 0% {
 opacity: 0;
 fill: none;
 stroke-dashoffset: 1400;
}
 30% {
 opacity: 10;
 fill: none;
 stroke-dashoffset: 1400;
}
 90% {
 fill: rgba(238,57,57,0);
}
 100% {
 opacity: 10;
 fill: rgba(238,57,57,1);
 stroke-dashoffset: 0;
}
}
 @keyframes ESlogo {
 0% {
 opacity: 0;
 fill: none;
 stroke-dashoffset: 1400;
}
 30% {
 opacity: 10;
 fill: none;
 stroke-dashoffset: 1400;
}
 90% {
 fill: rgba(238,57,57,0);
}
 100% {
 opacity: 10;
 fill: rgba(238,57,57,1);
 stroke-dashoffset: 0;
}
}
.TopPhone {
	float: left;
	font-size: 16px;
	margin-top: 15px;
	margin-left: 25px;
	letter-spacing: 3px;
	color: #484849;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.navbar {
	float: right;
	padding: 0;
	-webkit-transition: all .1s ease;
	-webkit-transition: all .5s ease;
	transition: all .5s ease
}
.navbar .navbar-nav .nav-link {
	display: block;
	margin: 18px 20px;
	padding: 0;
	font-weight: 500;
	font-size: 15px;
	text-transform: uppercase;
	color: #6c6c6c;
	line-height: normal;
	position: relative
}
.navbar .navbar-nav .active {	
	color: #ef373a!important;
	-webkit-transition: all .1s ease;
	-webkit-transition: all .5s ease;
	transition: all .5s ease
}
.navbar .navbar-nav .nav-link:active:after, .navbar .navbar-nav .nav-link:focus:after, .navbar .navbar-nav .nav-link:hover:after {
	width: 100%;
	z-index: -1;
	-webkit-transition: all .1s ease;
	-webkit-transition: all .5s ease;
	transition: all .5s ease
}
.navbar .navbar-nav .nav-item:last-child a {
	margin-right: 10px
}
.navbar .navbar-nav .nav-link:hover:before {
	-webkit-transform: scaleX(1);
	transform: scaleX(1)
}
.navbar .navbar-nav .nav-link:after {
	content: '';
	position: absolute;
	bottom: -8px;
	left: 0;
	width: 0%;
	-webkit-transform: translateX(0);
	transform: translateX(0);
	height: 2px;
	-webkit-transition: .35s;
	transition: .35s;
	background-color: #ef3738
}
.navbar .navbar-nav .nav-link:before {
	-webkit-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transition: all .3s ease-in-out 0s;
	transition: all .3s ease-in-out 0s
}
#ES-header {
	overflow: hidden;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	position: relative;
	background-color: #fafafa;
	background-image: url(/images/EmberlineStudios-HeroImage_2x.png);
	background-size: cover;
	background-repeat: no-repeat;
}
#ES-header .ES-header-wrap {
	position: absolute;
	top: 32%;
	left: 0;
	-webkit-transform: translate(0, -50%);
	        transform: translate(0, -50%);
	align-items: center;
	text-align: center;
	display: table;
	width: 100%;
}
#ES-header .ES-header-wrap h1 {
	font-size: 36px;
	color: #ef3738;
	text-align: center;
	margin: 0
}
#ES-header .ES-header-wrap p {
	font-size: 18px;
	text-align: center;
	margin: 20px 0 0 0
}
.social-icon {
	position: absolute;
	left: 35px;
	bottom: 20px;
	z-index: 7;
	font-size: 18px;
	font-weight: 300;
	color: #fff;
	letter-spacing: 4px;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.social-icon a {
	height: 20px;
	width: 20px;
	text-align: center;
	margin-bottom: 20px;
	display: block;
	color: #282828;
	-webkit-transition: all .3s ease-out;
	transition: all .3s ease-out;
}
.social-icon a:HOVER {
	color: #ef3739;
	text-decoration: none
}
.ES-name {
	color: #282828;
	-webkit-transform: translate(-50%, -50%) rotate(-90deg);
	transform: translate(-50%, -50%) rotate(-90deg);
	z-index: 5;
	font-size: 13px;
	font-weight: 300;
	text-transform: uppercase;
	font-family: 'proximanova-light';
	letter-spacing: 5px;
	width: 245px;
	margin-bottom: 185px;
	margin-left: 9px
}
.ES-name:after {
	content: '';
	position: absolute;
	bottom: 9px;
	left: -70px;
	width: 50px;
	height: 1px;
	background-color: #c5c4c3;
}
.ES-section {
	width: 100%;
	padding: 100px 0;
}
.ES-section h1 {
	font-size: 36px;
	margin-bottom: 35px
}

.abouthome {
	width: 100%;
	padding: 100px 0px 0px 0px;
}
.abouthome h1 {
	font-size: 36px;
	margin-bottom: 35px
}
.abouthome p {
	font-size: 18px;
	margin-bottom: 35px
}

.about p {
	font-size: 18px;
	margin-bottom: 35px
}
.button {
	display: inline-block;
	color: #050607;
	border: 1px solid #ef373a;
	padding: 11px 40px 10px;
	border-radius: 50px;
	overflow: hidden;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 2px;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	text-decoration: none!important;
	text-transform: uppercase
}
.button:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 50px;
	background: #ef373a;
	border: 1px solid #ef373a;
	-webkit-transform: scale(0);
	transform: scale(0);
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease;
	transition-timing-function: ease
}
.button:hover {
	color: #fff;
	border-color: #ef373a;
	-webkit-transform: translateY(-7px)!important;
	transform: translateY(-7px)!important;
}
.button:hover:before {
	-webkit-transform: scale(1);
	transform: scale(1)
}
._service {
	position: relative;
	list-style: none;
	outline: 0;
	margin-bottom: 25px
}
._service a {
	width: 100%;
	height: 100%;
	outline: 0;
}
._service figure {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	position: relative;
	float: left;
	cursor: pointer;
	width: 100%;
	margin: 100px 0 15px;
	padding: 100px 30px 30px;
	border-radius: 10px;
	color: #888ea0;
	background-color: #fff;
	z-index: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	box-shadow: 0 20px 50px rgba(136,142,160,.4);
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease; text-align: center
}
._service figure img {
	width: 100%;
	display: block;
	height: auto;
	margin-top: -200px;
	-webkit-transform: translateZ(20px);
	        transform: translateZ(20px);
	padding: 10px 20px;
	z-index: 1;
	-webkit-filter: drop-shadow(0 0px 0px );
	        filter: drop-shadow(0 0px 0px );
	-webkit-transition: all 0.1s linear;
	transition: all 0.1s linear
}
._service h2 {
	font-weight: 600;
	font-size: 22px;
	color: #323332;
	padding: 20px 0;
	margin: 0;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
._button-arrow {
	position: relative;
	width: 40px;
	height: 40px;
	padding: 10px 14px;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	box-shadow: 0 10px 50px rgba(0,0,0,.3);
	border-radius: 50px;
	overflow: hidden;
	margin-left: auto;
	margin-right: 0;
	background-color: #fff;
	color: #fff
}
._button-arrow .arrow {
	position: relative;
	width: 50px;
	height: 50px;
	font-size: 18px !important;
}
._button-arrow .arrow:before {
	content: '';
	width: 16px;
	border: 1px solid #bababa;
	display: inline-block;
	position: relative;
	background-color: #bababa;
	right: 2px;
	top: -7px
}
._button-arrow .arrow:after {
	content: '';
	position: absolute;
	top: 1px;
	right: 1px;
	height: 10px;
	width: 10px;
	border-bottom: 2px solid #bababa;
	border-right: 2px solid #bababa;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg)
}
._button-arrow p {
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	display: none;
	font-weight: 400;
	text-transform: uppercase;
	font-size: 14px;
	opacity: 0
}
._service figure:HOVER ._button-arrow {
	display: block;
	width: 127px;
	color: #fff;
	padding: 10px 14px;
	background-color: #ff0032
}
._service figure:HOVER ._button-arrow p {
	display: block;
	width: 100px;
	-webkit-transition: all 1.5s ease;
	transition: all 1.5s ease;
	text-align: center;
	opacity: 1
}
._service figure:HOVER {
	background-color: #ff0032;
	box-shadow: 0 30px 80px -20px rgba(255,0,50,1)
}
._service figure:HOVER h2 {
	color: #ffffff;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transform: translateZ(20px);
	        transform: translateZ(20px)
}
._Projects h1 {
	color: #ffffff;
	padding-top: 35px
}
._pro-BG {
	position: relative
}
._pro-BG:after {
	position: absolute;
	content: '';
	width: 80%;
	max-width: 1140px;
	left: 50%;
	top: 0;
	margin: 0 auto;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	height: 710px;
	background-color: #ef373a;
	z-index: -1;
	border-radius: 10px
}
.projektDiv {
	height: 501px;
	width: 335px;
	overflow: hidden;
	border-radius: 10px;
	-webkit-transition: box-shadow .28s ease;
	transition: box-shadow .28s ease;
	position: relative;
	box-shadow: 0 60px 70px -30px rgba(0,0,0,.3);
}
.projektDiv:after {
	content: '';
	width: 100%;
	left: 0;
	bottom: 0;
	position: absolute;
	z-index: 9;
	height: 100%;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, rgba(0,0,0,0)), to(rgba(0,0,0,.7)));
	background: linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,.7) 100%);
 filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00646978', endColorstr='#802a2c32', GradientType=0);
}
.projektDiv:HOVER {
	-webkit-transition: box-shadow .28s ease;
	transition: box-shadow .28s ease;
	box-shadow: 0 60px 70px -30px rgba(0,0,0,.5);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.projektDiv img {
	-webkit-transform: scale(1) translateZ(0);
	transform: scale(1) translateZ(0);
	-webkit-transition: transform .28s ease;
	-webkit-transition: -webkit-transform .28s ease;
	transition: -webkit-transform .28s ease;
	transition: transform .28s ease;
	transition: transform .28s ease, -webkit-transform .28s ease;
	width: 100%;
}
.projektDiv:hover img {
	-webkit-transform: scale(1) translateZ(0);
	transform: scale(1) translateZ(0);
	-webkit-transition: transform .5s ease;
	-webkit-transition: -webkit-transform .5s ease;
	transition: -webkit-transform .5s ease;
	transition: transform .5s ease;
	transition: transform .5s ease, -webkit-transform .5s ease;
}
.projektDiv .p-content {
	position: absolute;
	bottom: 20px;
	left: 20px;
	text-align: left;
	min-width: 295px;
	z-index: 10;
	padding: 0;
	display: block;
	text-shadow: 0px 5px 10px rgba(0,0,0,0.8);
	-webkit-transform: translateZ(10px);
	transform: translateZ(10px);
}
.projektDiv .p-content h2 {
	font-weight: 500;
	font-size: 22px;
	margin-top: 0;
	color: #fff;
	margin-bottom: 0
}
.projektDiv .p-content span {
	color: #fff;
	display: block;
	font-size: 14px;
	line-height: 1.5em;
	margin-bottom: 0;
	height: 36px;
}
._Projects .owl-item .item {
	-webkit-transform: scale(.95);
	        transform: scale(.95);
	-webkit-transition: all .5s;
	transition: all .5s;
}
/*._Projects .owl-item.active.center .item, */
._Projects .owl-item .item:HOVER {
	-webkit-transform: scale(1);
	        transform: scale(1);
	-webkit-transition: all .5s;
	transition: all .5s;
}
._Projects .owl-nav {
	position: absolute;
	top: 35%;
	width: 100%;
	display: none
}
._Projects .owl-nav .owl-prev {
	float: left;
	margin-left: -40px
}
._Projects .owl-nav .owl-next span, ._Projects .owl-nav .owl-prev span {
	color: #b2e2d4;
	font-size: 48px;
	border-radius: 100%;
	width: 65px;
	height: 65px;
	line-height: 65px;
	text-align: center
}
._Projects .owl-nav .owl-next {
	float: right;
	margin-right: -40px
}
._Projects .owl-nav .owl-next:HOVER, ._Projects .owl-nav .owl-next:focus, ._Projects .owl-nav .owl-prev:HOVER, ._Projects .owl-nav .owl-prev:focus {
	background-color: transparent!important;
	outline: 0 !important
}
._Projects .owl-dots {
	padding-top: 15px
}
.owl-carousel .owl-stage-outer {
	overflow: visible;
}
.owl-theme .owl-dots .owl-dot span {
	background: #ff7678
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
	background: #fff
}
._pro-BG .button {
	margin-top: 20px;
	background-color: #fff;
	border: solid 2px #ef373a
}
._pro-BG .button:HOVER {
	margin-top: 20px;
	border: solid 2px #fff
}
._partner .col-md-4 {
	margin-bottom: 20px;
	margin-top: 20px
}
._partner img {
	margin: auto;
	width: auto;
	height: 100px;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	opacity: .4;
	-webkit-transition: all .3s;
	transition: all .3s
}
._partner img:HOVER {
	-webkit-filter: grayscale(0);
	filter: grayscale(0);
	opacity: 1;
	-webkit-transition: all .5s;
	transition: all .5s;
}
.footer {
	border-top: solid 1px #ef373a;
	padding-top: 60px;
	padding-bottom: 60px;
	font-size: 18px;
	position: relative;
}
.footer h3 {
	font-size: 20px;
	text-align: right;
	margin-top: 21px
}
.footer ._ph {
	font-size: 20px;
	margin-bottom: 15px;
	display: inline-block;
	margin-top: 20px;
	color: #000;
}
.footer-email-container {
	display: table;
}
.footer ._email {
	color: #323332;
	font-size: 16px;
	text-decoration: none;
	display: table-cell;
	vertical-align: middle;
}
.footer ._email:HOVER {
	color: #ef373a;
	text-decoration: underline
}
.footer ._FLogo {
	width: auto;
	height: 110px
}
.copy-right .social-icon {
	position: relative;
	left: auto;
	bottom: auto;
	text-align: center;
	display: table;
	margin: auto;
	margin-bottom: 20px;
	font-size: 20px
}
.copy-right .social-icon a {
	display: inline-block;
	color: #323332;
	margin-left: 15px;
	margin-right: 15px
}
.copy-right .social-icon a:HOVER {
	color: #ef373a;
}
.copy-right {
	padding-top: 30px;
	padding-bottom: 30px;
	font-size: 14px;
	position: relative;
	text-align: center;
	background-color: #fafafa
}
.menu_icon {
	display: inline-block;
	cursor: pointer;
	position: fixed;
	right: -60px;
	top: 30px;
	height: 30px;
	width: 30px;
	z-index: 2000;
	text-align: center;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}
.menu-close {
	height: 30px;
}
.bar1, .bar2, .bar3 {
	width: 30px;
	height: 1px;
	background-color: #323332;
	margin: 8px 0;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}
.bar2 {
	background-color: #ef373a;
}
.menu-close .bar1 {
	display: block;
	width: 30px;
	height: 1px;
	position: absolute;
	top: 8px;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	background-color: #ef373a;
}
.menu-close .bar2 {
	opacity: 0;
	-webkit-transition: -webkit-transform .2s ease .2s;
	transition: -webkit-transform .2s ease .2s;
	transition: transform .2s ease .2s;
	transition: transform .2s ease .2s, -webkit-transform .2s ease .2s
}
.menu-close .bar3 {
	display: block;
	width: 30px;
	height: 1px;
	position: absolute;
	top: 8px;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	background-color: #ef373a;
}
.menu_icon a {
	display: inline-block;
	text-decoration: none;
}
.es_menu .logo {
	padding-top: 30px;
	position: absolute;
	z-index: 50
}
.no-scroll {
	overflow: hidden;
}
._bg-w .menu_icon {
	top: 14px
}
.es_menu {
	position: fixed;
	background-color: rgba(239,55,58,1);
	top: 0;
	right: -100%;
	width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.4s cubic-bezier(.19, 1, .22, 1) .2s;
	transition: all 0.4s cubic-bezier(.19, 1, .22, 1) .2s;
	-webkit-overflow-scrolling: touch;
	overflow-x: hidden;
}
.mw_bg {
	background: #fff;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: flex-start
}
.es_menu.open {
	opacity: 1;
	right: 0;
	visibility: visible;
	-webkit-transition: all .5s cubic-bezier(.19, 1, .22, 1) .3s;
	transition: all .5s cubic-bezier(.19, 1, .22, 1) .3s
}
.es_menu.open li {
	-webkit-animation: fadeInRight .5s ease forwards;
	animation: fadeInRight .5s ease forwards;
	-webkit-animation-delay: .5s;
	animation-delay: .5s;
}
.es_menu.open li:nth-of-type(2) {
	-webkit-animation-delay: .6s;
	animation-delay: .6s;
}
.es_menu.open li:nth-of-type(3) {
	-webkit-animation-delay: .7s;
	animation-delay: .7s;
}
.es_menu.open li:nth-of-type(4) {
	-webkit-animation-delay: .8s;
	animation-delay: .8s;
}
.es_menu.open li:nth-of-type(5) {
	-webkit-animation-delay: .9s;
	animation-delay: .9s;
}
.es_menu nav {
	position: relative;
	font-size: 65px;
	padding-left: 40px
}
.es_menu ul {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	display: inline-block;
	position: relative;
	height: 100%;
}
.es_menu ul li {
	display: block;
	position: relative;
	opacity: 0;
	line-height: 1em
}
.es_menu ul li a {
	display: inline-block;
	text-transform: uppercase;
	font-weight: 400;
	position: relative;
	color: #a8a8a8;
	text-decoration: none;
	overflow: hidden;
	line-height: 1.25em;
	font-size: 30px;
	background-image: -webkit-linear-gradient(left, #ff0032 50%, #a8a8a8 0);
	background-position: 100% 0;
	background-size: 200% 100%;
	font-family: 'Volte-Semibold', sans-serif;
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-transition: background-position 1s cubic-bezier(.19, 1, .22, 1)!important;
	transition: background-position 1s cubic-bezier(.19, 1, .22, 1)!important
}
.es_menu ul li a:after {
	content: '';
	display: inline-block;
	width: 0;
	height: 2px;
	background-color: #ff0032;
	position: absolute;
	bottom: 0;
	left: 0;
	-webkit-transition: width 1s cubic-bezier(.19, 1, .22, 1)!important;
	transition: width 1s cubic-bezier(.19, 1, .22, 1)!important;
	cursor: pointer;
	color: #ff0032;
}
.es_menu ul li a:hover {
	color: #ff0032!important;
	text-decoration: none;
	background-position: 0 0
}
.es_menu ul li a:hover:after {
	width: 100%
}
 @-webkit-keyframes fadeInRight {
 0% {
 opacity: 0;
 left: 20%;
}
 100% {
 opacity: 1;
 left: 0;
}
}
 @keyframes fadeInRight {
 0% {
 opacity: 0;
 left: 20%;
}
 100% {
 opacity: 1;
 left: 0;
}
}
.em-menu-bottom {
	position: absolute;
	bottom: 15px;
	left: 35px;
	font-size: 18px;
	color: #fff;
}
.em-menu-bottom h1 {
	font-size: 40px;
	line-height: 1;
	margin-bottom: 25px	
}
.em-menu-bottom a {
	display: block;
	color: #fff;
	font-size: 14px
}
.n-hiden .TopPhone, .n-hiden .navbar {
	margin-top: -100px;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.n-hiden .SD-pos {
	margin-left: -70px;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.n-hiden .menu_icon {
	right: 30px;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	top: 12px
}
.n-hiden .topheader {
	padding: 10px 20px 10px;
	background-color: rgba(255,255,255,0.8)
}
.n-hiden .EmberlineStudios {
	float: left;
	width: 50px;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.es_menu-social {
	top: 50%;
	right: 30px;
	left: auto;
	bottom: auto;
	-webkit-transform: translate(0, -50%);
	        transform: translate(0, -50%);
	align-items: center;
	text-align: center;
	display: block;
	height: 150px
}
.es_menu-social a {
	color: rgba(255,255,255,0.5)
}
.es_menu-social a:HOVER {
	color: rgba(255,255,255,1)
}
.subpage {
	padding-top: 200px;
	padding-bottom: 100px
}
.project-thumb {
	-webkit-transform: scale(.95);
	        transform: scale(.95);
	-webkit-transition: all .5s;
	transition: all .5s
}
.project-thumb:HOVER {
	-webkit-transform: scale(1);
	        transform: scale(1);
	-webkit-transition: all .5s;
	transition: all .5s
}
.project-thumb .projektDiv {
	height: auto;
	width: auto;
	margin-bottom: 20px
}
._Proj-filter-act {
	margin-bottom: 30px
}
._Proj-filter-act .button {
	padding: 5px 20px 5px;
	font-size: 13px;
	margin-right: 8px;
	letter-spacing: 1px;
	border: solid 1px #8d8d8d;
	color: #8d8d8d;
	margin-bottom: 5px;
	cursor: pointer
}
._Proj-filter-act .button:HOVER {
	color: #fff!important;
	border: solid 1px #ef373a;
	-webkit-transform: translateY(0px)!important;
	transform: translateY(0px)!important;
}
._Proj-filter-act .active .button {
	color: #fff;
	border: solid 1px #ef373a;
	background-color: #ef373a;
	-webkit-transform: translateY(0px)!important;
	transform: translateY(0px)!important;
}
.filters li {
	list-style: none;
	float: left
}
.filters-content .projektDiv .p-content {
	min-width: auto
}
._contact .col-form-label, ._contact .form-control {
	border: none;
	border-bottom: solid 1px #ececec;
	border-radius: 0px;
	font-size: 14px
}
._contact input {
	height: 40px
}
._contact textarea {
	padding-top: 12px
}
._contact .col {
	padding: 0px
}
._contact .form-group {
	margin-bottom: 10px
}
._contact input, ._contact textarea {
	background: -webkit-gradient(linear, left bottom, left top, color-stop(50%, #f3f2f2), color-stop(50%, #fff));
	background: linear-gradient(to top, #f3f2f2 50%, #fff 50%);
	background-size: 100% 200%;
	background-position: left top;
	-webkit-transition: all .5s ease;
	transition: all .5s ease
}
._contact input:focus, ._contact textarea:focus {
	background-position: left bottom;
	-webkit-transition: all .3s ease;
	transition: all .3s ease
}
._projectD {
	font-size: 16px
}
._projectD img {
	width: 100%;
	height: auto;
	border-radius: 10px
}
._aboutPage {
	background-color: #fafafa;
	position: relative;
	padding-bottom: 0
}
._aboutPage .nav-pills .nav-link.active, ._aboutPage .nav-pills .show>.nav-link {
	color: #000;
	background-color: transparent
}
._aboutPage .nav-pills .nav-link {
	color: #b7b7b7;
	padding: 0;
	font-size: 20px;
	margin-bottom: 20px
}
._aboutPage .nav-pills {
	margin-bottom: 70px;
	display: block
}
._aboutPage .tab-content img {
	width: 100%;
	height: auto;
	position: absolute;
	right: 0
}
._aboutPage .btn-explore {
	background-color: #fff;
	position: absolute;
	left: 0;
	bottom: -25px
}
._approach {
	padding-top: 250px
}
._approach ul {
	width: 100%;
	margin-top: 20px;
	margin-bottom: 60px
}
._approach ul li {
	text-align: center;
	font-size: 18px;
	font-family: proximanova-semibold
}
._approach .nav-pills .nav-link {
	font-size: 18px;
	font-family: proximanova-light;
	height: 70px;
	width: 70px;
	background-color: #fff;
	box-shadow: 0 10px 20px rgba(136,142,160,.4);
	line-height: 70px;
	padding: 0;
	margin: 30px auto 40px auto;
	border-radius: 50%!important;
	color: #000;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	border: 1px solid #fff;
	position: relative
}
._approach .nav-pills .nav-link:hover, ._approach .nav-pills .nav-link.active {
	background-color: #ef373a;
	color: #fff;
	padding: 5px;
	background: #ef373a;
	border: 1px solid #c0c0c0;
	background-clip: content-box;
	line-height: 70px;
	height: 80px;
	width: 80px;
	margin-top: 25px
}
._approach .nav-pills .nav-link:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 50%;
	left: 10px;
	right: 0;
	border-bottom: 1px dotted #c0c0c0;
	width: 250px
}
._approach .nav-pills .nav-link.active:after, ._approach .nav-pills .nav-link:hover:after {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	height: 78px;
	width: 78px;
	background-color: #fff
}
._approach .nav-item:last-child .nav-link:before {
	display: none
}
._approach .tab-content h3 {
	float: left;
	width: 390px;
	padding-right: 20px
}
._approach .tab-content p {
	font-size: 15px;
}
.philosophy {
	background-color: #ef373a;
	padding-top: 100px;
	padding-bottom: 100px;
	padding-right: 70px;
	color: #fff;
	position: relative;
	font-family: 'proximanova-light'
}
.philosophy strong{
	font-size: 18px;
	font-family: 'proximanova-semibold'
}
.philosophy:before {
	content: '';
	position: absolute;
	top: 0;
	left: -100%;
	width: 800px;
	height: 100%;
	background-color: #ef373a;
	z-index: -1
}
.philosophy p {
	font-size: 16px
}
.AB-logo {
	display: table;
	height: 100%;
	margin-left: auto
}
.MP {
	display: table-cell;
	vertical-align: middle
}
.AB-logo img {
	width: 380px
}
.SN{
	position: absolute;
    font-size: 130px;
    font-weight: 800;
    top: 0;
    right: 30px;
    color: #b4b4b4;
    z-index: -1; opacity: 0.15
}
.conBox strong {
	font-size: 16px;
	font-family: 'proximanova-semibold'
}
.about p strong {font-family: 'proximanova-semibold'}
.conBox ul{padding: 0; margin: 0}
.conBox ul li{list-style: none; margin-bottom: 15px}
.conBox ul li:last-child{margin-bottom: 0}
.conBox ul li::before { 
     display: inline-block;
    content: '';
    border-radius: 0.375rem;
    height:10px;
    width:10px;
    margin-right:10px;
    background-color: #fff
}
.UIUX .SN{left: 30px; right: auto; font-size: 200px}
.about p strong span{color: #ef373a; padding-right: 3px}
.UIUX .conBox h2{padding-top: 50px}


.mb-10 {
	margin-bottom: 10px!important
}
.mb-15 {
	margin-bottom: 15px!important
}
.mb-20 {
	margin-bottom: 20px!important
}
.mb-30 {
	margin-bottom: 30px!important
}
.mt-30 {
	margin-top: 30px!important
}
.mt-40 {
	margin-top: 40px!important
}
.mt-50 {
	margin-top: 50px!important
}
.OV-Beliefs {
	background-color: #fafafa;
	position: relative;
	padding-bottom: 50px
}
.OV-Beliefs:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	align-items: center;
	text-align: center;
	display: table;
	border-radius: 50%;
	width: 500px;
	height: 500px;
	background-color: #f6f6f6
}
.OV-cont {
	margin-bottom: 60px
}
.OV-cont h3 {
	font-size: 22px
}
.OV-cont p {
	font-size: 16px;
	margin-bottom: 0px
}
.CR-side h1 {
	margin-right: 190px
}
.C-Happy {
	padding-bottom: 0
}
.C-Happy p {
	margin-bottom: 10px;
	font-size: 16px
}
.C-Happy img {
	width: 110px;
	height: auto;
	-webkit-filter: grayscale(100);
	filter: grayscale(100);
	-webkit-transition: all .3s;
	transition: all .3s
}
.happyCL {
	margin-bottom: 50px
}
.happyCL:HOVER img {
	-webkit-filter: grayscale(0);
	filter: grayscale(0);
	-webkit-transition: all .5s;
	transition: all .5s
}
.subH-img {
	text-align: right;
	padding-right: 40px
}
.subH-img img {
	width: 500px
}
.subH-cont {
	width: 682px;
	padding-right: 15px;
	display: table-cell;
	vertical-align: middle;
}
.middleDiv {
	display: table;
	height: 100%
}
.conBox {
	padding: 50px;
	margin: 0
}
.conBox h2 {
	font-size: 28px;
	margin-bottom: 20px
}
.conBox p {
	font-size: 16px;
	margin-bottom: 0
}
.shadow {
	box-shadow: 0 10px 20px rgba(136,142,160,.4);
	background-color: #ef373a;
	color: #fff; border-radius: 15px
}
.GS-sec{padding: 15px 30px}
.GS-sec .col{padding: 50px 10px;font-size: 16px;text-align: center}
.BR-bottom{border-bottom: solid 1px #f47375}
.BR-right{border-right: solid 1px #f47375}

@media (max-width:1366px) {
#ES-header .ES-header-wrap h1 {
	font-size: 36px
}
body {
	font-size: 18px;
}
#ES-header .ES-header-wrap p {
	margin: 10px 0 0 0;
	font-size: 16px
}
.ES-name {
	font-size: 11px
}
.ES-section h1 {
	font-size: 32px;
	margin-bottom: 25px
}
.abouthome h1 {
	font-size: 32px;
	margin-bottom: 25px
}
._service h2 {
	font-size: 18px
}
.button, ._pro-BG .button {
	padding: 9px 30px 8px;
	font-size: 14px
}
.projektDiv {
	height: 480px
}
.footer ._FLogo {
	height: 85px
}
._pro-BG:after {
	height: 662px
}
.about p {
	font-size: 16px
}
._aboutPage .tab-content img {
	width: 90%
}
._aboutPage .nav-pills .nav-link {
	margin-bottom: 18px
}
._aboutPage .nav-pills {
	margin-bottom: 50px
}
.AB-logo img {
	width: 350px
}
}

@media (max-width:1280px) {
.subH-img img {
	width: 100%
}
}

@media (max-width:1024px) {
.navbar .navbar-nav .nav-link {
	margin: 18px 15px;
	font-size: 14px
}

._service h2 {
	padding: 5px 0 15px 0px
}
._service figure {
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 20px
}
.projektDiv {
	height: 454px;
	width: 300px
}
._pro-BG:after {
	height: 637px
}
._partner img {
	height: 80px
}
.filters-content .projektDiv .p-content h2 {
	font-size: 18px
}
._approach {
	padding-top: 120px
}
}

@media (max-width:992px) {
.navbar-nav {
	display: none
}
.menu_icon {
	right: 30px
}
._pro-BG:after {
	width: 65%
}
#ES-header .ES-header-wrap h1 {
	font-size: 36px
}
._aboutPage .nav-pills .nav-link {
	margin-bottom: 10px
}
.R800bg {
	box-shadow: 0 10px 20px rgba(136,142,160,.4);
	background-color: #ef373a;
	color: #fff
}
.W800bg {
	background-color: #fff;
	color: #282828;
	box-shadow: none!important
}
}

@media (max-width:800px) {
._aboutPage .tab-content img {
	width: 100%
}
._approach .tab-content h3 {
	display: block;
	width: 100%;
	margin-bottom: 15px
}
.AB-logo img {
	width: 230px
}
.C-Happy img {
	-webkit-filter: grayscale(0);
	filter: grayscale(0)
}
#ES-header .ES-header-wrap p {
	font-size: 16px;
	margin: 15px 120px;
}

}

@media (max-width:767px) {
._partner img {
	height: 100px
}
#ES-header {
	height: 500px
}
.ES-name {
	width: 197px
}
.ES-section h1 {
	margin-bottom: 25px
}

.EmberlineStudios {
	width: 65px
}
body {
	font-size: 16px;
}
.abouthome p{
	font-size: 16px
}
.topheader {
	padding-top: 20px
}
.TopPhone {
	font-size: 15px;
	margin-left: 15px;
	letter-spacing: 2px;
}
._Projects .owl-item .item {
	display: table;
	margin: auto
}
._pro-BG:after {
	width: 85%
}
._partner img {
	height: 80px
}
._service figure {
	padding-left: 10px;
	padding-right: 10px;
	text-align: center
}
._service figure img {
	width: 100%;
	padding: 15px 30px;
}
.footer ._FLogo {
	margin: auto;
	display: block
}
.footer ._ph {
	display: block
}
.footer h3 {
	text-align: center
}
.P767 {
	padding-top: 0;
	padding-bottom: 0
}
._service {
	margin-bottom: 0
}
.copy-right .social-icon {
	margin-bottom: 10px
}
.copy-right {
	padding-top: 20px;
	padding-bottom: 20px
}
.menu_icon {
	top: 20px
}
.es_menu ul li a {
	font-size: 22px
}
.mw_bg {
	min-height: 600px;
	max-height: 100vh;
	width: calc(100% - 100px)
}
.es_menu-social {
	right: 20px
}
.em-menu-bottom {
	font-size: 16px
}
.em-menu-bottom {
	z-index: 50;
	left: 150px;
	color: #a8a8a8
}
.em-menu-bottom a {
	color: #a8a8a8
}
.w-mob {
	width: 95px;
	background-color: rgba(239,55,58,1)
}
.EmberlineStudios-m {
	padding-top: 15px;
	padding-left: 0;
	width: 65px;
}
.em-menu-bottom h1 {
	display: none
}
.es_menu-social {
	top: auto;
	right: auto;
	left: 40px;
	bottom: 20px;
	-webkit-transform: translate(0, 0);
	        transform: translate(0, 0)
}
.es_menu {
	background-color: rgba(255,255,255,1)
}
.ES-name {
	letter-spacing: 3px;
	margin-bottom: 145px
}
.ES-name::after {
	left: -45px;
	width: 36px
}
._contact .col-form-label {
	flex: 0 0 100%;
	max-width: 100%;
	border: none;
	padding-left: 12px
}
._aboutPage .nav-pills .nav-link {
	font-size: 16px;
	margin-bottom: 5px
}
._approach ul li {
	float: left;
	width: 20%;
	font-size: 14px
}
._approach .nav-pills .nav-link:hover, ._approach .nav-pills .nav-link.active {
	line-height: 50px;
	height: 60px;
	width: 60px;
	margin-top: 18px
}
._approach .nav-pills .nav-link {
	font-size: 16px;
	height: 50px;
	width: 50px;
	line-height: 50px;
	margin: 23px auto 0px auto
}
._approach .nav-pills .nav-link::before {
	width: 130px
}
._approach .nav-pills .nav-link.active::after, ._approach .nav-pills .nav-link:hover::after {
	height: 58px;
	width: 59px
}
.philosophy::before {
	left: -60px;
	width: 140%
}
.philosophy {
	padding-right: 15px
}
.AB-logo {
	margin: auto;
	padding-top: 80px;
	padding-bottom: 80px
}
.abouthome h1 {
	margin-bottom: 25px
}
._aboutPage .nav-pills .nav-link {
	margin-bottom: 10px
}
.subH-img {
	padding: 30px
}
.smPadd {
	padding-top: 150px;
	padding-bottom: 50px
}
}

@media (max-width:480px) {
#ES-header{
	position: relative;
	background-color: #fafafa;
	background-image: url(/images/EmberlineStudios-HeroImage_1x.png);
	background-size: cover;
	background-repeat: no-repeat;
}
#ES-header .ES-header-wrap h1 {
	font-size: 32px
}
.ES-section h1 {
	margin-bottom: 25px
}
#ES-header .ES-header-wrap p, .about p, .footer ._email {
	font-size: 14px
}
.TopPhone {
	display: none;
}
.em-menu-bottom a {
	font-size: 12px
}
.social-icon a {
	margin-bottom: 15px
}
body {
	font-size: 14px;
}
.abouthome p{
	font-size: 14px
}
.abouthome h1 {
	margin-bottom: 25px
}
.filters-content .project-thumb {
	-webkit-transform: scale(1);
	        transform: scale(1);
	-webkit-transition: all .5s;
	transition: all .5s;
	margin: 20px 50px
}
._Proj-filter-act {
	margin: 25px
}
.filters-content .projektDiv .p-content h2 {
	font-size: 22px;
}
._approach ul li {
	width: 20%;
	font-size: 14px
}
._aboutPage .tab-content img {
	width: 95%;
	right: 15px
}
.AB-logo img {
	width: 180px
}
._aboutPage .btn-explore {
	left: 174px
}
.SN {
    font-size: 100px; top: -15px}
.UIUX .SN {
    font-size: 150px; top: 6px}
.GS-sec .col {
    padding: 35px 5px;
    font-size: 14px
}
._pro-BG {
	position: relative
}
._pro-BG:after {
	position: absolute;
	content: '';
	top: 0;
	margin: 0 auto;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	height: 630px;
	background-color: #ef373a;
	z-index: -1;
	border-radius: 10px
}
.owl-theme .owl-dots .owl-dot span {
	display: none;
}


}

@media (max-width:425px) {
.ES-section h1 {
	font-size: 26px
}
#ES-header .ES-header-wrap h1 {
	font-size: 26px
}
#ES-header .ES-header-wrap p {
	margin: 20px 80px;
}
.abouthome h1 {
	font-size: 26px
}
.abouthome p {
	font-size: 14px
}
body {
	font-size: 14px;
}
.TopPhone {
	font-size: 14px;
	letter-spacing: 1px;
	display: none;
}
.projektDiv {
	height: 414px;
	width: 280px
}
._Projects h1 {
	padding-top: 25px;
	margin-bottom: 15px
}
._pro-BG::after {
	height: 568px
}

.topheader {
	padding: 0;
	padding-top: 15px
}
.mw_bg {
	min-height: 100vh;
	max-height: 100vh
}
.social-icon {
	left: 37px
}
.filters-content .project-thumb {
	-webkit-transform: scale(1);
	        transform: scale(1);
	-webkit-transition: all .5s;
	transition: all .5s;
	margin: 20px 30px
}
.filters-content .projektDiv .p-content h2 {
	font-size: 18px
}
._Proj-filter-act {
	margin: 0;
	padding: 0px 25px
}
._Proj-filter-act .button {
	font-size: 11px
}
._aboutPage .btn-explore {
	left: 147px
}
._approach ul li {
	width: 20%;
	font-size: 11px;
	letter-spacing: 0;
	padding: 0
}
._approach .nav-pills .nav-link::before {
	width: 70px
}
._approach ul {
	padding-left: 10px
}
._approach .nav-pills .nav-link:hover, ._approach .nav-pills .nav-link.active {
	line-height: 40px;
	height: 50px;
	width: 50px
}
._approach .nav-pills .nav-link {
	height: 40px;
	width: 40px;
	line-height: 40px
}
._approach .nav-pills .nav-link::before {
	width: 80px
}
._approach .nav-pills .nav-link.active::after, ._approach .nav-pills .nav-link:hover::after {
	height: 49px;
	width: 49px
}
.CR-side h1 {
	margin-right: 0;
}
.subH-img {
	padding: 0px;
	margin-top: 30px
}
.conBox {
	padding: 30px
}
.conBox h2 {font-size: 22px}
.GS-sec {
    padding: 15px 30px;
}
._pro-BG {
	position: relative
}
._pro-BG:after {
	position: absolute;
	content: '';
	top: 0;
	margin: 0 auto;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	height: 560px;
	background-color: #ef373a;
	z-index: -1;
	border-radius: 10px
}
.owl-theme .owl-dots .owl-dot span {
	display: none;
}

}

@media (max-width:375px) {
._aboutPage .btn-explore {
	left: 120px
}
._approach ul li {
	width: 20%
}
.GS-sec .col {
    letter-spacing: 0;
    font-size: 13px

}
#ES-header .ES-header-wrap p {
	margin: 20px 65px;
}
}


@media (max-width:320px) {
.menu_icon {
	right: 20px;
	top: 20px
}
.TopPhone {
	font-size: 14px;
	letter-spacing: 1px;
	display: none;
}
#ES-header .ES-header-wrap p {
	margin: 20px 42px;
}
._pro-BG::after {
	width: 100%
}
.em-menu-bottom {
	left: 15px
}
.es_menu nav {
	padding-left: 25px
}
.EmberlineStudios-m {
	width: 50px
}
.w-mob {
	width: 81px
}
.em-menu-bottom {
	left: 114px
}
.es_menu-social {
	left: 30px
}
.filters-content .project-thumb {
	margin: 20px 10px
}
._aboutPage .btn-explore {
	left: 94px
}
._approach ul li {
	width: 20%
}
._approach ul li {
	font-size: 10px;
	letter-spacing: 0;
}
}
#contactBtn {
	background-color: #fff;
}

/* Reveal block */
.reveal-holder {
	position: relative;
	display: inline-block;
	overflow: hidden;
}
.reveal-holder .reveal-block {
	position: absolute;
	top: 0;
	width: 100%;
	height: 101%;
	background: white;
}
.reveal-holder .reveal-block::before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #ef373a;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.5s;
	        transition-duration: 0.5s;
}
.reveal-block.gradient::before {
	background: -webkit-gradient(linear, right top, left top, from(#eebf56), to(#f46ca3));
	background: linear-gradient(to left, #eebf56, #f46ca3);
}
.reveal-block.gradient-two::before {
	background: -webkit-gradient(linear, right top, left top, from(#1b9edf), to(#af74c3));
	background: linear-gradient(to left, #1b9edf, #af74c3);
}
.reveal-block.extra {
	background: #1b9edf;
}
.reveal-block.extra::before {
	background: #af74c3;
}
.reveal-block.top.aos-animate::before, .reveal-block.bottom.aos-animate::before {
	-webkit-transform: scaleY(1);
	        transform: scaleY(1);
}
.reveal-block.left.aos-animate::before, .reveal-block.right.aos-animate::before {
	-webkit-transform: scaleX(1);
	        transform: scaleX(1);
}
.reveal-block.top::before {
	-webkit-transform: scaleY(0);
	        transform: scaleY(0);
	-webkit-transform-origin: 0% 100%;
	        transform-origin: 0% 100%;
}
.reveal-block.left::before {
	-webkit-transform: scaleX(0);
	        transform: scaleX(0);
	-webkit-transform-origin: 100% 0%;
	        transform-origin: 100% 0%;
}
.reveal-block.right::before {
	-webkit-transform: scaleX(0);
	        transform: scaleX(0);
	-webkit-transform-origin: 0% 100%;
	        transform-origin: 0% 100%;
}
.reveal-block.bottom::before {
	-webkit-transform: scaleY(0);
	        transform: scaleY(0);
	-webkit-transform-origin: 100% 0%;
	        transform-origin: 100% 0%;
}
.alivio_image{
	width: 100%;
  height: 300px;
  object-fit: contain;
}
.pricing-detail-head{
	margin: auto;
	padding-top: 60px;
}
.pricing-heading{
	text-align: center;
}
.pricing-text{
	margin-bottom: 0rem;
}
.card1{
	box-shadow: 0px 0px 20px rgb(0 0 0 / 16%), 0 0px 0px rgb(0 0 0 / 23%);
	border-radius: 25px;
	padding: 27px;
	height: 100%;
}
.clients-logos{
	width: 70%;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.ecom ul li {
	color: #EF373A;
}
.ecommerece{
	padding: 50px;
	margin: 0;
}
.ecommerece h2{
		font-size: 28px;
    margin-bottom: 20px;
}
.ecommerece ul li {
	list-style: none;
	margin-bottom: 15px;
	margin-left: 35px;
}
.discover{
	margin-left: 15%;
	margin-top: 12%;
}
.ecommerece ul li::before{
	content: "\2022";
  color: #EF373A;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
	font-size: 36px;
}
.revenue{
margin-left: 23%;
margin-top: 20%;
}
.margin-minus{
	margin-bottom: -6%;
}
.card-image{
	box-shadow: 0;
}
/* AOS animate */
[data-aos="reveal-top"], [data-aos="reveal-left"], [data-aos="reveal-right"], [data-aos="reveal-bottom"] {
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-delay: 1s;
	        transition-delay: 1s;
}
[data-aos="reveal-top"] {
	-webkit-transform: scaleY(1);
	        transform: scaleY(1);
}
[data-aos="reveal-top"].aos-animate {
	-webkit-transform: scaleY(0);
	        transform: scaleY(0);
	-webkit-transform-origin: 100% 0%;
	        transform-origin: 100% 0%;
}
[data-aos="reveal-left"] {
	-webkit-transform: scaleX(1);
	        transform: scaleX(1);
}
[data-aos="reveal-left"].aos-animate {
	-webkit-transform: scaleX(0);
	        transform: scaleX(0);
	-webkit-transform-origin: 0% 100%;
	        transform-origin: 0% 100%;
}
[data-aos="reveal-right"] {
	-webkit-transform: scaleX(1);
	        transform: scaleX(1);
}
[data-aos="reveal-right"].aos-animate {
	-webkit-transform: scaleX(0);
	        transform: scaleX(0);
	-webkit-transform-origin: 100% 0%;
	        transform-origin: 100% 0%;
}
[data-aos="reveal-bottom"] {
	-webkit-transform: scaleY(1);
	        transform: scaleY(1);
}
[data-aos="reveal-bottom"].aos-animate {
	-webkit-transform: scaleY(0);
	        transform: scaleY(0);
	-webkit-transform-origin: 0% 100%;
	        transform-origin: 0% 100%;
}
[data-aos="reveal-item"] {
	visibility: hidden;
	-webkit-transition-property: visibility;
	transition-property: visibility;
	-webkit-transition-duration: 0s;
	        transition-duration: 0s;
}
[data-aos="reveal-item"].aos-animate {
	visibility: visible;
}

#contactBtn {
    outline:none;
}

.consultation-btn {
    position:relative;
    top:30px;
}

.long-story-font {
    font-family: 'Poppins', sans-serif;
}
.dark-bg .navbar-nav .nav-link, .dark-bg .navbar-nav .active, .dark-bg .TopPhone {
  color: #fff !important
}
.dark-bg .bar1, .dark-bg .bar3 {
  background-color: #ffffff;
}
.dark-bg .menu-close .bar1, .dark-bg .menu-close .bar3 {
  background-color: #ef373a;
}
.n-hiden .dark-bg .bar1, .n-hiden .dark-bg .bar3 {
  background-color: #282828 !important;
}
.vds-header {
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  background-color: #000;
  text-align: center;
  color: #fff;
  padding-top:120px;
}
.vds-header video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  opacity: 0.5;
}
.vds-header h1 {
  font-size: 128px;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  letter-spacing: normal;
  line-height: 1;
  margin: 0;
  margin-bottom:20px;
}
.vds-header h2 {
  font-size: 80px;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  letter-spacing: normal;
  line-height: 1;
  margin: 0;
}
.vds-header p {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.vds-header .col-md-12 {
  flex: 0 0 766px;
  max-width: 766px;
  margin: auto
}
.button-vds, .button-vds:hover, .button-vds:focus {
  display: inline-block;
  color: #fff;
  border: 1px solid #fff;
  padding: 11px 40px 10px;
  border-radius: 50px;
  overflow: hidden;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none !important;
  text-transform: uppercase;
  background-color: #ef373a;
}
.vds-header .w-b {
  background-color: #fff;
  padding:10px;
  border-radius: 10px;
  border: solid 1px #ef373a;
  letter-spacing: normal;
  color: #272827;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 35px;
  margin-top: 35px;
}
.vds-header .w-b span {
  color: #ef373a;
  font-weight: normal;
  display: block;
  font-size: 20px;
}
.breadwinner {
  margin: 0;
  padding: 100px 0 100px 0;
  background-image: url(/images/breadwinner-bg.svg);
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #EE3A39;
}
.breadwinner .card {
  border: none !important;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 20px 50px rgba(136, 142, 160, .3);
  margin: auto;
}
.breadwinner .card-header {
  background-color: #ef373a;
  padding: 15px;
  color: #fff;
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  font-weight: 700;
  text-align: center
}
.breadwinner p {
  font-size: 18px;
}
.breadwinner strong, .deal-sec-card {
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  font-weight: 700;
}
.deal-sec {
  margin: 0;
  padding: 100px 0 100px 0;
  background-image: url(/images/deal-bg.svg);
  background-position: top center;
  background-size: cover
}
.deal-sec p, .branding-sec p {
  font-size: 18px;
}
.deal-sec h2, .branding-sec h2 {
  font-size: 50px;
  line-height: 1
}
.deal-sec-card {
  background-color: #fff;
  font-size: 28px;
  line-height: 1;
  text-align: center;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 30px rgba(136, 142, 160, .4);
  margin: 50px 0;
  position: relative;
  font-weight: 600;
}
.deal-sec-card:after {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  height: 12px;
  width: 12px;
  border-top: 2px solid #ef373a;
  border-left: 2px solid #ef373a;
}
.deal-sec-card:before {
  content: '';
  position: absolute;
  bottom: 15px;
  right: 15px;
  height: 12px;
  width: 12px;
  border-bottom: 2px solid #ef373a;
  border-right: 2px solid #ef373a;
}
.HVCO-PDF {}
.HVCO-PDF h1 {
  color: #323332
}
.HVCO-PDF ._pro-BG:after {
  background-color: #fff;
  box-shadow: 0 20px 50px rgba(136, 142, 160, .3);
}
.HVCO-PDF .button-vds {
  margin-top: 30px
}
.HVCO-PDF .projektDiv {
  box-shadow: none
}
.HVCO-PDF .owl-theme .owl-dots .owl-dot.active span, .HVCO-PDF .owl-theme .owl-dots .owl-dot:hover span {
  background: #ef373a;
}
.branding-sec {
  margin: 0;
  padding: 80px 0 80px 0;
}
.branding-sec h4 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 500
}
.branding-sec ul, .sec-bottom ul {
  padding: 20px 0 20px 15px;
}
.branding-sec ul li, .sec-bottom ul li {
  margin-bottom: 15px;
  font-size: 16px;
}
.branding-sec img, .letConnect img {
  width: 100%;
  height: auto
}
.sec-bottom {
  background-image: url(/images/consultation-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  padding: 80px 0;
}
.sec-bottom p {
  font-size: 18px
}
.sec-bottom h1 {
  font-size: 60px
}
.sec-bottom h2 {
  font-size: 38px
}
.sec-bottom h3 {
  font-size: 30px
}
.sec-bottom ul {
  width: 50%;
  float: left
}
.sec-bottom ul li {
  margin-bottom: 0;
  font-size: 18px
}
.sec-bottom .button-vds {
  margin-top: 30px
}
.sec-bottom .col-12 {
  margin: auto;
  flex: 0 0 65%;
  max-width: 65%;
}
.letConnect {
  padding: 80px 0;
}
.letConnect h2 {
  margin-bottom: 25px;
  font-size: 36px
}
.letConnect .button-vds {
  margin-top: 15px;
  font-size: 14px
}
.letConnect .form-control {
  background-color: #f9f9f9
}
.letConnect .form-control:focus {
  border: 1px solid #ef373a;
}
textarea {
  resize: none
}
.eb-footer {
  text-align: center
}
.eb-footer ._Footer-Logo {
  height: 115px;
  display: block;
  margin: 15px auto;
}
.eb-footer .f_email {
  color: #333;
  font-size: 16px;
  margin: auto;
}
.eb-footer h3 {
  font-size: 22px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: bolder;
  display: block;
}
.eb-footer .f_email {
  color: #333;
  font-size: 16px;
  margin: auto;
}
.pb-30 {
  padding-bottom: 30px !important
}
.d-mob {
  display: none
}
.h-mob {
  display: block
}
@media (max-width:1024px) {
  .vds-header h2 {
    font-size: 70px;
  }
  .vds-header h1 {
    font-size: 111px;
  }
  .vds-header p {
    font-size: 18px
  }
  .vds-header .w-b {
    font-size: 30px;
    padding: 12px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .vds-header .w-b span {
    font-size: 16px
  }
  .vds-header .col-md-12 {
    flex: 0 0 667px;
    max-width: 667px;
  }
  .breadwinner .card-header {
    font-size: 25px
  }
  .breadwinner p, .deal-sec p, .branding-sec p, .sec-bottom p {
    font-size: 16px
  }
  .deal-sec h2, .branding-sec h2 {
    font-size: 45px
  }
  .deal-sec-card {
    font-size: 22px
  }
  .branding-sec h4 {
    font-size: 20px;
  }
  .sec-bottom h3 {
    font-size: 26px;
  }
  .sec-bottom h2, .letConnect h2 {
    font-size: 32px;
  }
  .sec-bottom h1 {
    font-size: 50px;
  }
  .sec-bottom ul li {
    margin-bottom: 0;
    font-size: 16px;
  }
}
@media (max-width:800px) {
  .deal-sec-card {
    font-size: 18px;
  }
  .sec-bottom .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width:767px) {
  .d-mob {
    display: block
  }
  .h-mob {
    display: none
  }
  .vds-header .col-md-12 {
    flex: 0 0 460px;
    max-width: 460px;
  }
  .vds-header h2 {
    font-size: 45px;
  }
  .vds-header h1 {
    font-size: 72px;
  }
  .vds-header p {
    font-size: 16px
  }
  .vds-header .w-b {
    font-size: 24px;
    padding: 10px;
  }
  .vds-header .w-b span, .breadwinner p, .deal-sec p {
    font-size: 14px
  }
  .button-vds, .button-vds:hover, .button-vds:focus {
    font-size: 14px
  }
  .deal-sec h2, .branding-sec h2 {
    font-size: 35px
  }
  .deal-sec-card {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .deal-sec .button-vds {
    margin-top: 20px;
  }
  .HVCO-PDF h1 {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .hidd-sm {
    display: none
  }
  .vds-header {
    min-height: 550px;
  }
  .branding-sec .button-vds {
    display: table;
    margin: auto
  }
}
@media (max-width:480px) {
  .vds-header {
    min-height: 100vh;
  }
  .breadwinner {
    background-image: url(/images/breadwinner-bg-mob.svg);
  }
  .breadwinner .card {
    margin: 0 30px
  }
  .deal-sec, .ES-section, .breadwinner, .branding-sec, .sec-bottom, .letConnect {
    padding: 60px 0 60px 0;
  }
  .branding-sec img {
    width: 80%;
    margin: auto;
    margin-bottom: 30px
  }
  .sec-bottom ul {
    width: 100%;
    list-style: none;
    padding: 0 35px;
    text-align: center
  }
  .letConnect .col-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width:425px) {
  .eb-footer h3 {
    font-size: 22px !important;
  }
  .eb-footer ._Footer-Logo {
    height: 80px;
  }
  .vds-header .col-md-12 {
    flex: 0 0 378px;
    max-width: 378px;
  }
  .vds-header h2 {
    font-size: 38px;
  }
  .vds-header h1 {
    font-size: 60px;
  }
  .vds-header p {
    font-size: 14px;
  }
  .vds-header .w-b {
    font-size: 18px;
    padding: 10px 0;
  }
  .vds-header .w-b span {
    font-size: 12px;
  }
  .breadwinner .card-header {
    font-size: 22px;
  }
  .deal-sec h2, .branding-sec h2 {
    font-size: 32px;
  }
  .eb-footer h3 {
    font-size: 22px !important;
  }
  .eb-footer ._Footer-Logo {
    height: 80px;
  }
  .copy-right .social-icon a {
    margin-left: 22px;
    margin-right: 22px;
  }
  .social-icon a {
    height: 22px;
    width: 22px;
  }
  .social-icon a svg {
    height: 22px !important;
  }
  .branding-sec img {
    width: 95%;
  }
}
@media (max-width:375px) {
  .HVCO-PDF h1 {
    font-size: 20px;
  }
  ._pro-BG:after {
    width: 90%;
  }
  .sec-bottom h3 {
    font-size: 24px;
  }
  .sec-bottom h2 {
    font-size: 28px;
  }
  .branding-sec h4 {
    font-size: 18px;
    letter-spacing: normal;
  }
}
@media (max-width:320px) {
  .vds-header .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .vds-header h2 {
    font-size: 30px;
  }
  .vds-header h1 {
    font-size: 48px;
    margin-bottom: 10px;
  }
  .vds-header p {
    line-height: 22px;
  }
  .vds-header .w-b span {
    font-size: 11px;
  }
  .vds-header .w-b {
    margin-bottom: 20px;
    margin-top: 20px
  }
}
@font-face {
  font-family: 'FuturaPTBook';
  src: url(/fonts/FuturaPTBook.woff2) format('woff2'), url(/fonts/FuturaPTBook.woff) format('woff'), url(/fonts/FuturaPTBook.ttf) format('truetype');
}
@font-face {
  font-family: 'FuturaPTBold';
  src: url(/fonts/FuturaPTBold.woff2) format('woff2'), url(/fonts/FuturaPTBold.woff) format('woff'), url(/fonts/FuturaPTBold.ttf) format('truetype');
}
.e-book-header {
  background-image: url(/images/e-book-header-bg.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto;
  height: 860px;
  justify-content: center;
  display: flex;
  font-family: 'FuturaPTBook', sans-serif;
  padding-top:50px;
}
.e-book-header .e-book-img {
  width: auto;
  max-height: 660px;
  margin-top: 30px;
}
.e-book-header .form-control {
  background-color: #fff !important
}
.right-side-sec {
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
  color: #fff;
  letter-spacing: 1px
}
.right-side-sec .emberline-logo {
  width: auto;
  height: 85px;
  display: block;
  margin: 0px auto 10px auto
}
.right-side-sec p {
  font-size: 25px;
  margin: 16px auto;
  width: 85%;
  display: inline-block
}
.right-side-sec h2 {
  font-size: 70px;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  letter-spacing: normal;
  line-height: 1;
  margin: 0;
}
.right-side-sec span {
  font-size: 20px;
  width: 74%;
  margin: 0px auto 20px auto;
  display: block;
}
.eb-form {
  width: 430px;
  display: block;
  margin: auto
}
.eb-form .form-control {
  width: 430px;
  font-size: 16px;
  border-radius: 5px;
  height: 45px;
  border: none;
  padding-left: 55px;
  background-image: url(/images/env-icon.svg);
  background-position: 12px 13px;
  background-size: 28px;
  background-repeat: no-repeat;
  background-color: #E6E6EB;
  font-family: 'proximanova-light', sans-serif;
}
.button-sub, .button-sub:hover, .button-sub:focus {
  display: inline-block;
  padding: 6px 40px 6px;
  overflow: hidden;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  text-decoration: none !important;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #ef373a;
  background-color: #ef373a;
  font-family: 'Oswald', sans-serif;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  width: 100%;
  margin-top: 15px;
}
.button-gr, .button-gr:hover, .button-gr:focus {
  border: 1px solid #13AC1E;
  background-color: #13AC1E;
}
.sec-2 {
  margin: 0;
  padding: 80px 0 80px 0
}
.sec-2 .eb-form {
  margin: 0
}
.sec-2 ul {
  padding: 20px 0 20px 15px;
}
.sec-2 ul li {
  margin-bottom: 15px;
  font-size: 16px;
}
.sec-2 .e-book-img2 {
  width: 100%;
  height: auto
}
.sec-2 h3 {
  font-family: 'FuturaPTBold', sans-serif;
  font-size: 38px;
  letter-spacing: normal;
}
.sec-3 {
  background-image: url(/images/bg-img-002.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color: #fff;
  padding: 80px 0;
  font-family: 'FuturaPTBook', sans-serif;
}
.sec-3 h2 {
  font-size: 70px;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  letter-spacing: normal;
  line-height: 1;
  margin: 15px 0;
}
.sec-3 p {
  font-size: 25px;
  margin: 0
}
.sec-3 .eb-form {
  margin-top: 30px
}
.last-chance {
  background-image: url(/images/bg-img-003.jpg);
}
.last-chance h2 {
  width: 50%;
  margin: auto
}
.last-chance .eb-form .button-sub {
  font-size: 22px;
}
.last-chance h3 {
  font-family: 'FuturaPTBold', sans-serif;
  text-transform: none;
  font-weight: normal;
  width: 50%;
  margin: auto;
  font-size: 38px
}
.connect-us {
  margin: 0;
  padding: 80px 0 80px 0;
  text-align: center;
}
.connect-us .eb-form {
  margin: 0
}
.sec-2 .e-book-img2 {
  width: 100%;
  height: auto
}
/*.connect-us h3{font-family: 'FuturaPTBold', sans-serif;}	*/
.connect-us h3 {
  font-size: 38px;
  font-weight: normal;
}
.connect-us h2 {
  font-size: 64px;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  letter-spacing: normal;
  line-height: 1;
  margin: 15px 0;
  color: #ef373a;
}
.connect-us p {
  font-size: 20px;
  width: 60%;
  margin: auto
}
.connect-us .eb-form-sub .form-control {
  width: 100%
}
.connect-us .eb-form-sub {
  width: 490px;
  margin: auto;
  margin-top: 15px;
}
.connect-us .eb-form-sub .button-sub {
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 0;
  margin: 0
}
.e-book-img-sm {
  display: none
}
.sec-3 .p-sm {
  font-size: 14px;
  margin: 0;
  margin-top: 10px;
}
.pr-60 {
  padding-right: 60px
}
.pl-60 {
  padding-left: 60px
}
.eb-footer ._Footer-Logo {
  height: 115px; display: block; margin: 15px auto;
}
.eb-footer .f_email{color: #333; font-size: 16px; margin: auto;}
.eb-footer  h3 {
  font-size: 22px;
  text-align: center;
  margin-top: 15px; margin-bottom: 15px; font-weight: bolder; display: block;
}
.copy-right .social-icon{margin-bottom: 10px;}
.copy-right .social-icon a { 
  margin-left: 30px;
  margin-right: 30px;
}
.social-icon a {
  height: 30px;
  width: 30px; 
}

.pb-30{padding-bottom: 30px;}
@media (max-width:1024px) {
  .e-book-header {
    height: 670px;
  }
  .e-book-header .e-book-img {
    width: 100%;
    height: 100%;
    max-height: none;
    margin-top: 20px;
  }
  .right-side-sec h2, .sec-3 h2, .connect-us h2 {
    font-size: 50px;
  }
  .right-side-sec .emberline-logo {
    height: 55px;
    margin: 0px auto 0px auto;
  }
  .right-side-sec p {
    width: 100%;
  }
  .right-side-sec span {
    width: 90%
  }
  .sec-2 h3, h3 {
    font-size: 35px;
  }
  .eb-form, .eb-form .form-control {
    width: 385px;
  }
  .last-chance h3 {
    width: 75%
  }
}
@media (max-width:800px) {
  .e-book-header {
    height: 600px;
    background-size: 110% auto;
  }
  .right-side-sec h2, .sec-3 h2, .connect-us h2 {
    font-size: 42px;
  }
  .eb-form, .eb-form .form-control {
    width: 290px;
  }
  .button-sub, .button-sub:hover, .button-sub:focus {
    padding: 6px 20px 6px;
  }
  .right-side-sec p {
    width: 100%;
    font-size: 18px
  }
  .right-side-sec span {
    margin: 0px auto 20px auto;
  }
  .right-side-sec span {
    width: 100%;
    font-size: 16px
  }
  .last-chance .eb-form .button-sub {
    font-size: 18px;
    margin-top: 0px
  }
  .pr-60 {
    padding-right: 10px
  }
  .pl-60 {
    padding-left: 10px
  }
  .sec-2 h3, h3 {
    font-size: 25px;
  }
  .sec-2 ul li {
    margin-bottom: 8px;
    font-size: 15px;
  }
  .sec-3 p {
    font-size: 20px;
  }
  .sec-2 ul {
    padding: 10px 0 10px 15px;
  }
  .last-chance h3 {
    font-size: 28px
  }
  .connect-us h3 {
    font-size: 25px;
  }
  .connect-us p {
    font-size: 18px;
    width: 75%;
  }
  .connect-us .eb-form-sub {
    width: 420px;
  }
  .eb-form .form-control {
    height: 40px;
    background-size: 26px;
    background-position: 12px 12px;
  }
  .button-sub, .button-sub:hover, .button-sub:focus {
    font-size: 18px;
  }
}
@media (max-width:768px) {
  .e-book-header {
    height: 590px;
    background-size: 114% auto;
  }
  .connect-us .eb-form-sub {
    width: 370px;
  } 
  .copy-right .social-icon a { 
    margin-left: 25px;
    margin-right: 25px;
  }
  .social-icon a {
    height: 25px;
    width: 25px; 
  }
  .social-icon a svg {
    height: 24px !important; 
  }
}
@media (max-width:767px) {
  .right-side-sec {
    padding-top: 20px
  }
  .right-side-sec .emberline-logo {
    height: 50px;
  }
  .e-book-header {
    background-color: #4032a8;
    height: 855px;
    background-size: 114% auto;
  }
  .hidden-767 {
    display: none
  }
  .e-book-img-sm {
    display: block;
    width: 63%;
    margin: 0 auto 15px auto;
  }
  .right-side-sec p {
    margin: 10px auto;
  } 
  .row-reverse {
    flex-wrap: wrap-reverse;
  }
  .sec-2 .e-book-img2 {
    margin-bottom: 20px;
    margin-top: 20px;
  } 
  .sec-2 .e-book-img2 {
    margin-top: 0px;
  }
  .eb-footer h3 {
    font-size: 24px !important;
}
.eb-footer ._Footer-Logo {
  height: 95px;
}
}
@media (max-width:480px) {
  .e-book-header {
    height: 1015px;
  }
  .right-side-sec .emberline-logo {
    height: 60px;
  }
  .right-side-sec p {
    font-size: 24px;
  }
  .right-side-sec h2, .sec-3 h2, .connect-us h2 {
    font-size: 50px;
  }
  .e-book-img-sm {
    width: 100%;
  }
  .eb-form, .eb-form .form-control {
    width: 100%;
  }
  .right-side-sec span {
    font-size: 18px
  }
  .sec-2 h3, h3 {
    font-size: 35px
  }
  .sec-2 ul li {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .sec-3 p {
    font-size: 24px;
  }
  .last-chance h3, .connect-us h3 {
    font-size: 38px;
  }
  .connect-us p, .connect-us .eb-form-sub, .last-chance h3 {
    width: 100%
  }
}
@media (max-width:425px) {
  .eb-form, .eb-form .form-control, .connect-us .eb-form-sub {
    width: 100%;
  }
  .connect-us h3 {
    font-size: 30px;
  }
  .last-chance h3 {
    font-size: 35px;
  }
  .eb-footer h3 {
    font-size: 22px !important;
}
.eb-footer ._Footer-Logo {
  height: 80px;
}
.copy-right .social-icon a { 
  margin-left: 22px;
  margin-right: 22px;
}
.social-icon a {
  height: 22px;
  width: 22px; 
}
.social-icon a svg {
  height: 22px !important; 
}
}
@media (max-width:375px) {
  .e-book-header {
    height: 950px;
  }
  .right-side-sec h2, .sec-3 h2, .connect-us h2 {
    font-size: 42px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;600;700&display=swap');
@font-face {
  font-family: 'FuturaPTBook';
  src: url(/fonts/FuturaPTBook.woff2) format('woff2'), url(/fonts/FuturaPTBook.woff) format('woff'), url(/fonts/FuturaPTBook.ttf) format('truetype');
}
@font-face {
  font-family: 'FuturaPTBold';
  src: url(/fonts/FuturaPTBold.woff2) format('woff2'), url(/fonts/FuturaPTBold.woff) format('woff'), url(/fonts/FuturaPTBold.ttf) format('truetype');
}
.e-book-header {
  background-image: url(/images/e-book-header-bg.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto;
  height: 860px;
  justify-content: center;
  display: flex;
  font-family: 'FuturaPTBook', sans-serif;
  padding-top:50px;
}
.e-book-header .e-book-img {
  width: auto;
  max-height: 660px;
  margin-top: 30px;
}
.e-book-header .form-control {
  background-color: #fff !important
}
.right-side-sec {
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
  color: #fff;
  letter-spacing: 1px
}
.right-side-sec .emberline-logo {
  width: auto;
  height: 85px;
  display: block;
  margin: 0px auto 10px auto
}
.right-side-sec p {
  font-size: 25px;
  margin: 16px auto;
  width: 85%;
  display: inline-block
}
.right-side-sec h2 {
  font-size: 70px;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  letter-spacing: normal;
  line-height: 1;
  margin: 0;
}
.right-side-sec span {
  font-size: 20px;
  width: 74%;
  margin: 0px auto 20px auto;
  display: block;
}
.eb-form {
  width: 430px;
  display: block;
  margin: auto
}
.eb-form .form-control {
  width: 430px;
  font-size: 16px;
  border-radius: 5px;
  height: 45px;
  border: none;
  padding-left: 55px;
  background-image: url(/images/env-icon.svg);
  background-position: 12px 13px;
  background-size: 28px;
  background-repeat: no-repeat;
  background-color: #E6E6EB;
  font-family: 'proximanova-light', sans-serif;
}
.button-sub, .button-sub:hover, .button-sub:focus {
  display: inline-block;
  padding: 6px 40px 6px;
  overflow: hidden;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  text-decoration: none !important;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #ef373a;
  background-color: #ef373a;
  font-family: 'Oswald', sans-serif;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  width: 100%;
  margin-top: 15px;
}
.button-gr, .button-gr:hover, .button-gr:focus {
  border: 1px solid #13AC1E;
  background-color: #13AC1E;
}
.sec-2 {
  margin: 0;
  padding: 80px 0 80px 0
}
.sec-2 .eb-form {
  margin: 0
}
.sec-2 ul {
  padding: 20px 0 20px 15px;
}
.sec-2 ul li {
  margin-bottom: 15px;
  font-size: 16px;
}
.sec-2 .e-book-img2 {
  width: 100%;
  height: auto
}
.sec-2 h3 {
  font-family: 'FuturaPTBold', sans-serif;
  font-size: 38px;
  letter-spacing: normal;
}
.sec-3 {
  background-image: url(/images/bg-img-002.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color: #fff;
  padding: 80px 0;
  font-family: 'FuturaPTBook', sans-serif;
}
.sec-3 h2 {
  font-size: 70px;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  letter-spacing: normal;
  line-height: 1;
  margin: 15px 0;
}
.sec-3 p {
  font-size: 25px;
  margin: 0
}
.sec-3 .eb-form {
  margin-top: 30px
}
.last-chance {
  background-image: url(/images/bg-img-003.jpg);
}
.last-chance h2 {
  width: 50%;
  margin: auto
}
.last-chance .eb-form .button-sub {
  font-size: 22px;
}
.last-chance h3 {
  font-family: 'FuturaPTBold', sans-serif;
  text-transform: none;
  font-weight: normal;
  width: 50%;
  margin: auto;
  font-size: 38px
}
.connect-us {
  margin: 0;
  padding: 80px 0 80px 0;
  text-align: center;
}
.connect-us .eb-form {
  margin: 0
}
.sec-2 .e-book-img2 {
  width: 100%;
  height: auto
}
/*.connect-us h3{font-family: 'FuturaPTBold', sans-serif;}	*/
.connect-us h3 {
  font-size: 38px;
  font-weight: normal;
}
.connect-us h2 {
  font-size: 64px;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  letter-spacing: normal;
  line-height: 1;
  margin: 15px 0;
  color: #ef373a;
}
.connect-us p {
  font-size: 20px;
  width: 60%;
  margin: auto
}
.connect-us .eb-form-sub .form-control {
  width: 100%
}
.connect-us .eb-form-sub {
  width: 490px;
  margin: auto;
  margin-top: 15px;
}
.connect-us .eb-form-sub .button-sub {
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 0;
  margin: 0
}
.e-book-img-sm {
  display: none
}
.sec-3 .p-sm {
  font-size: 14px;
  margin: 0;
  margin-top: 10px;
}
.pr-60 {
  padding-right: 60px
}
.pl-60 {
  padding-left: 60px
}
.eb-footer ._Footer-Logo {
  height: 115px; display: block; margin: 15px auto;
}
.eb-footer .f_email{color: #333; font-size: 16px; margin: auto;}
.eb-footer  h3 {
  font-size: 22px;
  text-align: center;
  margin-top: 15px; margin-bottom: 15px; font-weight: bolder; display: block;
}
.copy-right .social-icon{margin-bottom: 10px;}
.copy-right .social-icon a { 
  margin-left: 30px;
  margin-right: 30px;
}
.social-icon a {
  height: 30px;
  width: 30px; 
}

.pb-30{padding-bottom: 30px;}
@media (max-width:1024px) {
  .e-book-header {
    height: 670px;
  }
  .e-book-header .e-book-img {
    width: 100%;
    height: 100%;
    max-height: none;
    margin-top: 20px;
  }
  .right-side-sec h2, .sec-3 h2, .connect-us h2 {
    font-size: 50px;
  }
  .right-side-sec .emberline-logo {
    height: 55px;
    margin: 0px auto 0px auto;
  }
  .right-side-sec p {
    width: 100%;
  }
  .right-side-sec span {
    width: 90%
  }
  .sec-2 h3, h3 {
    font-size: 35px;
  }
  .eb-form, .eb-form .form-control {
    width: 385px;
  }
  .last-chance h3 {
    width: 75%
  }
}
@media (max-width:800px) {
  .e-book-header {
    height: 600px;
    background-size: 110% auto;
  }
  .right-side-sec h2, .sec-3 h2, .connect-us h2 {
    font-size: 42px;
  }
  .eb-form, .eb-form .form-control {
    width: 290px;
  }
  .button-sub, .button-sub:hover, .button-sub:focus {
    padding: 6px 20px 6px;
  }
  .right-side-sec p {
    width: 100%;
    font-size: 18px
  }
  .right-side-sec span {
    margin: 0px auto 20px auto;
  }
  .right-side-sec span {
    width: 100%;
    font-size: 16px
  }
  .last-chance .eb-form .button-sub {
    font-size: 18px;
    margin-top: 0px
  }
  .pr-60 {
    padding-right: 10px
  }
  .pl-60 {
    padding-left: 10px
  }
  .sec-2 h3, h3 {
    font-size: 25px;
  }
  .sec-2 ul li {
    margin-bottom: 8px;
    font-size: 15px;
  }
  .sec-3 p {
    font-size: 20px;
  }
  .sec-2 ul {
    padding: 10px 0 10px 15px;
  }
  .last-chance h3 {
    font-size: 28px
  }
  .connect-us h3 {
    font-size: 25px;
  }
  .connect-us p {
    font-size: 18px;
    width: 75%;
  }
  .connect-us .eb-form-sub {
    width: 420px;
  }
  .eb-form .form-control {
    height: 40px;
    background-size: 26px;
    background-position: 12px 12px;
  }
  .button-sub, .button-sub:hover, .button-sub:focus {
    font-size: 18px;
  }
}
@media (max-width:768px) {
  .e-book-header {
    height: 590px;
    background-size: 114% auto;
  }
  .connect-us .eb-form-sub {
    width: 370px;
  } 
  .copy-right .social-icon a { 
    margin-left: 25px;
    margin-right: 25px;
  }
  .social-icon a {
    height: 25px;
    width: 25px; 
  }
  .social-icon a svg {
    height: 24px !important; 
  }
}
@media (max-width:767px) {
  .right-side-sec {
    padding-top: 20px
  }
  .right-side-sec .emberline-logo {
    height: 50px;
  }
  .e-book-header {
    background-color: #4032a8;
    height: 855px;
    background-size: 114% auto;
  }
  .hidden-767 {
    display: none
  }
  .e-book-img-sm {
    display: block;
    width: 63%;
    margin: 0 auto 15px auto;
  }
  .right-side-sec p {
    margin: 10px auto;
  } 
  .row-reverse {
    flex-wrap: wrap-reverse;
  }
  .sec-2 .e-book-img2 {
    margin-bottom: 20px;
    margin-top: 20px;
  } 
  .sec-2 .e-book-img2 {
    margin-top: 0px;
  }
  .eb-footer h3 {
    font-size: 24px !important;
}
.eb-footer ._Footer-Logo {
  height: 95px;
}
}
@media (max-width:480px) {
  .e-book-header {
    height: 1015px;
  }
  .right-side-sec .emberline-logo {
    height: 60px;
  }
  .right-side-sec p {
    font-size: 24px;
  }
  .right-side-sec h2, .sec-3 h2, .connect-us h2 {
    font-size: 50px;
  }
  .e-book-img-sm {
    width: 100%;
  }
  .eb-form, .eb-form .form-control {
    width: 100%;
  }
  .right-side-sec span {
    font-size: 18px
  }
  .sec-2 h3, h3 {
    font-size: 35px
  }
  .sec-2 ul li {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .sec-3 p {
    font-size: 24px;
  }
  .last-chance h3, .connect-us h3 {
    font-size: 38px;
  }
  .connect-us p, .connect-us .eb-form-sub, .last-chance h3 {
    width: 100%
  }
}
@media (max-width:425px) {
  .eb-form, .eb-form .form-control, .connect-us .eb-form-sub {
    width: 100%;
  }
  .connect-us h3 {
    font-size: 30px;
  }
  .last-chance h3 {
    font-size: 35px;
  }
  .eb-footer h3 {
    font-size: 22px !important;
}
.eb-footer ._Footer-Logo {
  height: 80px;
}
.copy-right .social-icon a { 
  margin-left: 22px;
  margin-right: 22px;
}
.social-icon a {
  height: 22px;
  width: 22px; 
}
.social-icon a svg {
  height: 22px !important; 
}
}
@media (max-width:375px) {
  .e-book-header {
    height: 950px;
  }
  .right-side-sec h2, .sec-3 h2, .connect-us h2 {
    font-size: 42px;
  }
}